<template>
  <!-- Content -->
  <div>
    <b-spinner
      v-if="isLoading"
      class="center-x my-3"
      small
      variant="primary"
      type="grow"
    />
    <b-card v-if="item && isLoading === false" no-body>
      <b-dropdown
        id="dropdown-dropleft"
        variant="link"
        right
        class="dropdown-user"
        no-caret
      >
        <template #button-content>
          <feather-icon
            v-if="canEdit"
            icon="Edit2Icon"
            size="20"
            class="text-primary edit-icon"
          />
        </template>
        <b-dropdown-text class="bg-light">
          <p class="user-name font-weight-bolder mb-0">
            {{ $t("edit-dropdown.title") }}
          </p>
        </b-dropdown-text>
        <b-dropdown-item v-b-modal.modal-edit-grant>
          {{ $t("edit-dropdown.details") }}
        </b-dropdown-item>
        <!-- Edit classifiers -->
        <b-dropdown-item
          v-if="canEdit"
          @click="isClassifiersModalVisible = true"
          >{{ $t("members.edit.classifiers.name") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="isCustomFieldsModalVisible = true"
          >{{ $t("members.edit.custom-fields.label") }}
        </b-dropdown-item>
      </b-dropdown>

      <feather-icon
        v-if="canShare"
        icon="Share2Icon"
        v-b-tooltip.hover.bottom
        :title="$t('share.tooltip')"
        size="20"
        class="text-primary share-icon"
        @click="isShareModalVisible = true"
      />
      <feather-icon
        v-if="isStaff"
        v-b-tooltip.hover.bottom
        icon="Trash2Icon"
        :title="$t('unshare.tooltip')"
        size="20"
        style="cursor:pointer"
        class="text-primary delete-icon"
        @click="isUnshareModalVisible = true"
      />
      <b-card-body>
        <b-row class="my-2">
          <!-- LEFT: grant Image Container -->
          <!-- <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            style="align-items: flex-start !important;"
          >
            <div class="d-flex align-items-center justify-content-center px-4">
              <b-img
                :src="item.bgURL || ProductPlaceholder"
                :alt="`Image of ${item.name}`"
                class="grant-img"
                style="border-radius: 1.2rem"
                fluid
              />
            </div>
          </b-col> -->

          <!-- RIGHT: grant Details -->
          <b-col cols="12" md="7">
            <!-- grant Name -->
            <h3>{{ item.name }}</h3>
            <b-card-text v-if="item.headline">{{ item.headline }}</b-card-text>
            <!-- grant Owner -->
            <b-card-text v-if="item.ownedByMember" class="item-company mb-0 mt-1">
              {{ $t("grants.created-by") }}
              <span class="text-primary">{{ item.ownedByMember.name }}</span>
            </b-card-text>
            <!-- Price -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 v-if="item.budget && item.budget > 0" class="item-price mr-1">
                {{ $t("form-create-item.budget") }}: {{ item.budget }}€
                <!-- {{ item.currency }} -->
              </h4>
            </div>
            <!-- grant Description -->
            <b-card-text>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="item.description" />
            </b-card-text>
            <hr v-if="false" />
            <div v-if="false" class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
              >
                <feather-icon icon="CalendarIcon" class="mr-50" />
                <span>Meeting</span>
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
              >
                <feather-icon icon="MessageSquareIcon" class="mr-50" />
                <span>Chat</span>
              </b-button>
            </div>
            <!--Custom fields-->
            <b-row
              v-for="(field, index) in item.custom"
              :key="index"
              class="mb-1"
            >
              <b-col v-if="field.value" class="font-weight-bold">
                {{ translatedFieldName(field) }}
              </b-col>
              <b-col>
                {{ field.value }}
              </b-col>
            </b-row>
            <!-- Classifiers -->
            <hr v-if="classifiers && classifiers.length > 0" class="mt-2" />
            <div
              v-if="classifiers && classifiers.length > 0"
              v-key="classifiers"
              class="my-50"
            >
              <div v-for="(value, name) in sortedClassifiers" :key="value.key">
                <div class="w-100">{{ name }}:</div>
                <span v-for="v in value" :key="v.index">
                  <b-badge class="mb-50 mr-50" :style="classifierStyle(v)">
                    {{
                      translateTranslationTable(
                        $store.state.locale.currentLocale,
                        v.name
                      )
                    }}
                  </b-badge>
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <classifiers-modal
        v-model="isClassifiersModalVisible"
        :item-key="item.key"
        morph-type="grant"
        v-on:profileUpdated="fetchClassifiers"
        :classifiers="classifiers"
      />
      <!-- sortedCustomFields -->
      <customfields-modal
        v-model="isCustomFieldsModalVisible"
        :item="item"
        v-on:profileUpdated="updateCustomFields"
        morph-type="grant"
      />
      <!-- Share grant -->
      <share-modal
        v-model="isShareModalVisible"
        :item-name="item.name"
        model-type="grant"
        type="grants"
        :model-key="item.key"
      />
      <!-- Delete grant -->
      <unshare-modal
        v-model="isUnshareModalVisible"
        model-type="grant"
        @remove-item="handleRemoveItem"
      />

      <!-- Edit grant -->
      <b-modal
        id="modal-edit-grant"
        :title="$t('grants.modal-edit-title')"
        cancel-variant="outline-secondary"
        :ok-title="$t('form.actions.save')"
        :cancel-title="$t('form-create-item.cancel')"
        @ok="handleEditGrants"
        centered
        size="lg"
      >
        <b-form>
          <b-form-group>
            <label for="title"> {{ $t("form-create-item.title") }}:</label>
            <b-form-input id="name" v-model="itemInput.name" type="text" />
          </b-form-group>
          <b-form-group>
            <label for="price"> {{ $t("form-create-item.budget") }}:</label>
            <b-form-input id="price" v-model="itemInput.budget" type="number" />
          </b-form-group>
          <b-form-group>
            <label class="mt-2" for="description"
              >{{ $t("form-create-item.description") }}:</label
            >
            <quill-editor v-model="itemInput.description" />
          </b-form-group>

          <!-- <p class="mt-3">{{ $t("form-create-item.image") }}:</p>
          <div class="border rounded p-2">
            <p class="mb-50">
              {{ $t("form-create-item.image-select") }}
            </p>
            <b-media
              no-body
              vertical-align="center"
              class="d-flex align-items-center flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="imageSrc"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                  style="background: #c0c0c0;"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-inline-block d-flex align-items-center pl-2">
                  <b-form-file
                    v-model="imageChallenge"
                    class="pointer"
                    :accept="FileTypes"
                    :placeholder="$t('form-create-item.image-placeholder')"
                    :browse-text="$t('form-create-item.image-button')"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div> -->
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import ProductPlaceholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import CustomfieldsModal from "@/@core/components/modal/CustomfieldsModal.vue";
import ClassifiersModal from "@/@core/components/modal/ClassifiersModal.vue";
import ShareModal from "@/@core/components/modal/ShareModal.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { quillEditor } from "vue-quill-editor";
import { checkPermissions } from "@/@core/utils/roles-utils";
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
// import FileTypes from '@core/constants/FileTypes';
import { VBTooltip } from 'bootstrap-vue';

export default {
  name: "GrantDetails",
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    CustomfieldsModal,
    ClassifiersModal,
    ShareModal,
    UnshareModal,
    quillEditor,
    ToastificationContentVue,
  },
  /* props: {
    id: {
      type: String,
      required: true,
    },
  }, */
  data() {
    return {
      isLoading: true,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      isShareModalVisible: false,
      imageChallenge: null,
      imageSrc: null,
      isUnshareModalVisible: false,
      // lastLoadedPage: 1,
    };
  },
  computed: {
    // FileTypes(){
    //   return FileTypes
    // },
    id() {
      return this.$route.params.id;
    },
    item() {
      return this.$store.getters.grant(this.id);
    },
    itemInput() {
      const item = this.$store.getters.item("grants", this.id);
      return item;
    },
    ProductPlaceholder() {
      return ProductPlaceholder;
    },
    classifiersData() {
      if (this.$store.getters.classifiers["grant"]) {
        return this.$store.getters.classifiers["grant"].unpaginated;
      } else {
        return [];
      }
    },
    classifiers() {
      return this.classifiersData;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canEdit() {
      return (
        this.loggedUser.key === this.item?.ownedByMember?.key ||
        this.isStaff ||
        checkPermissions(
          "update",
          "grant",
          this.loggedMemberRoles,
          this.$store.getters.currentCollective
        )
      );
    },
    canShare() {
      return (
        this.loggedUser.key === this.item?.ownedByMember?.key ||
        this.isStaff ||
        checkPermissions(
          "share",
          "grant",
          this.loggedMemberRoles,
          this.$store.getters.currentCollective
        )
      );
    },
    sortedClassifiers() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ].push(item);
        }
      });
      return newObject;
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    // this.fetchClassifiers();
  },
  methods: {
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async handleEditGrants() {
      try {
        const result = await this.$store.dispatch("editItem", {
          item: {
            itemType: "grants",
            requestConfig: {
              key: this.id,
              grantKey: this.id,
              name: this.itemInput.name,
              description: this.itemInput.description,
              budget: this.itemInput.budget,
            },
          },
          file: this.imageChallenge,
        });
        this.item.bgURL = result.bgURL;
        this.itemInput = {};
        this.updateBreadcrumbs();
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    /*  async fetchData() {
    }, */
    async fetchClassifiers() {
      await this.$store.dispatch("getItems", {
        storedKey: "grant",
        itemType: "classifiers",
        forceAPICall: true,
        page: 1,
        requestConfig: {
          grantKey: this.item.key,
        },
      });
      this.isLoading = false;
      this.updateBreadcrumbs();
    },
    translateTranslationTable,
    translatedFieldName(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field.name
      );
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        "background-color": classifier.backgroundColor,
      };
    },
    updateCustomFields(items) {
      if (typeof items === "object") {
        let value = Object.values(items)[0];
        let key = Object.keys(items)[0];

        for (let row of this.item.custom) {
          if (row.key === key) {
            row.value = value;
          }
        }
      } else if (Array.isArray(items)) {
        for (let item of items) {
          let value = Object.values(item)[0];
          let key = Object.keys(item)[0];

          for (let row of this.item.custom) {
            if (row.key === key) {
              row.value = value;
            }
          }
        }
      }
    },
    async fetchData() {
      await this.$store.dispatch("getItems", {
        itemType: "grants",
        page: 1,
        requestConfig: {
          grantKey: this.id,
        },
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: "grants.breadcrumb-text.grant-list",
          to: { name: "grants" },
        },
        {
          text: this.item?.name
            ? this.item.name
            : "grants.breadcrumb-text.grant-details",
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
    async handleRemoveItem(response) {
      if (response === true) {
        console.log('Deleting this grant...');
        const itemInputKey = this.itemInput.key;
        // console.log('itemInputKey', itemInputKey)

        try {
          const itemData = {
            itemType: 'grants',
            key: itemInputKey,
            storedKey: "grants",
            modelName: 'grant',
            tableName: 'tenders',
            modelKey: itemInputKey,
          };
          // console.log('itemData', itemData)

          const result = await this.$store.dispatch('deleteItemGeneric', {
            item: itemData,
            noSet: true,
          });
          console.log('result', result)

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', {
                item: this.$t('unshare.singular-items.grant'),
              }),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.grant'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }

        this.$emit('load-items');
        this.$emit('close-modal', itemInputKey);
        this.isUnshareModalVisible = false;
        this.$router.push({
          name: "grants",
          params: { communityId: this.$route.params.communityId },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.grant-img {
  max-height: 500px;
}
.share-icon {
  position: absolute;
  top: -48px;
  right: 0;
  cursor: pointer;
}
.edit-icon {
  position: absolute;
  top: -48px;
  right: 60px;
  cursor: pointer;
}
.delete-icon {
  position: absolute;
  top: -48px;
  right: 120px;
  cursor: pointer;
}
.b-nav-dropdown .dropdown-toggle::after {
  display: none !important;
}
#dropdown-dropleft {
  list-style-type: none !important;
}
</style>
